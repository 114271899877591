<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="primary" space="40">
      <base-section-heading
        color="grey lighten-2"
        title="Ajouter / remplacer une notice thermostat"
      />
      <v-container>
        <v-row>
          <v-radio-group v-model="create">
            <v-radio label="Créer un nouveau modèle de thermostat" value="1" />
            <v-radio label="Ajouter à un modèle existant" value="2" />
          </v-radio-group>
        </v-row>

        <v-row v-if="create == 1">
          <v-text-field
            label="nom du modèle"
            v-model="thermostatName"
          ></v-text-field>
        </v-row>

        <v-row v-else-if="create == 2">
          <v-select
            :items="models"
            label="Selectionner le modèle"
            v-model="thermostatName"
            :menu-props="{ top: true, offsetY: true }"
          ></v-select>
        </v-row>

        <v-row v-if="thermostatName.length > 0">
          <v-select
            :items="lang"
            label="Choix de la langue"
            v-model="selectedLang"
            :menu-props="{ top: true, offsetY: true }"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-row>

        <v-row v-if="selectedLang !== undefined">
          <v-file-input
            accept="application/pdf"
            label="Fichier de notice"
            v-model="file"
          ></v-file-input>
        </v-row>

        <v-row v-if="uploading">
          <v-col cols="12" md="6">
            <v-progress-linear
              indeterminate
              background-color="blue-grey"
              color="lime"
            />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-btn
            v-if="file !== null"
            class="mr-4"
            color="success"
            type="submit"
            @click="upload"
          >
            Envoyer
          </v-btn>
          <v-btn class="mr-4" color="error" @click="clear">
            Annuler
          </v-btn>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "FileNoticeUploadForm",

  computed: {
    models() {
      return this.$store.getters.models.map(x => x.name);
    }
  },
  data: () => ({
    create: 0,
    selected_menu: 1,
    thermostatName: "",
    lang: [
      { code: "fr", name: "Français" },
      { code: "en", name: "Anglais" },
      { code: "pl", name: "Polonais" },
      { code: "pt", name: "Portuguais" },
      { code: "ru", name: "Russe" },
      { code: "es", name: "Espagnol" }
    ],
    selectedLang: undefined,
    file: null,
    uploading: false
  }),
  methods: {
    upload() {
      this.uploading = true;
      let formData = new FormData();

      formData.append("filetoupload", this.file);
      formData.append("lang", this.selectedLang.code);
      formData.append("model", this.thermostatName);

      this.$store
        .dispatch("upload_notice", formData)
        .then(() => {
          this.uploading = false;
          this.clear();
        })
        .catch(() => {
          this.uploading = false;
          this.clear();
        });
    },
    clear() {
      this.thermostatName = "";
      this.create = 0;
      this.selectedLang = undefined;
      this.file = null;
    }
  }
};
</script>

<style scoped></style>
